import * as React from "react";
import { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardActionArea } from "@mui/material";
import "../css/Plans.css";
import ModalformBasicApp from "../components/Modal/app-dev/Modal-App-Basic";
import ModalformStandardApp from "../components/Modal/app-dev/Modal-App-Standard";
import ModalformPremiumApp from "../components/Modal/app-dev/Modal-App-Premium";
import AppCards from "../components/AppCards";
import MobileLottieAnimation from "../components/MobileAnimation";
import Typewriter from "../components/TypeWriter.jsx";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import ConsultationBanner from "../components/consultationBanner/consultationBanner.jsx";
import MoreServices from "../components/moreServices/moreServices.jsx";
import PricingCards from "../components/pricingCards/priceCards.jsx";
import ServiceForm from "../components/ServiceForm.jsx";
import ContactForm from "../components/ContactForm.jsx";
import WebAgency from "../components/webAgency/webAgency.jsx";

export default function PlansApp({ plans }) {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState({ open: false, type: "" });
  const [selectedPlan, setSelectedPlan] = useState("");

  const handleCloseModal = () => setShowModal({ open: false, type: "" });

  const handleShow = (planName) => {
    setSelectedPlan(planName);
    setShowModal({ open: true, type: planName.trim() });
  };

  return (
    <>
      <ServiceForm
        title={"Top-Notch App Development Services in UAE"}
        description={
          "Looking for expert app development services? At Softmark Solutions, we craft cutting-edge apps tailored to your needs, from Android to iOS. Let’s bring your app idea to life."
        }
        buttonText={"Get In Touch"}
      />

      <div className="main-container container">
        <h2>
          SoftMark Solutions -{" "}
          <Typewriter strings={["Innovating the Mobile Experience"]} />
        </h2>
        <div className="text-img-cont">
          <h5 className="sub-paragraph app-para">
            In today's mobile-first world, having a robust and intuitive mobile
            application is essential for reaching and engaging your audience. At
            SoftMark Solutions, we specialize in developing high-quality mobile
            apps that deliver seamless user experiences and drive business
            growth. From concept to launch, our team is committed to providing
            innovative solutions that meet your specific needs and keep you
            ahead in the competitive mobile landscape.
          </h5>
          <div className="mobile">
            <MobileLottieAnimation />
          </div>
        </div>
      </div>

      <div className="card-heading-service">
        <h2 className="heading-underline-service">
          Comprehensive App Development Services Tailored for You
        </h2>
        <div className="container text" style={{ marginTop: "2rem" }}>
          <p>
            Softmark Solutions offers the best Android app development services,
            iOS app development in Dubai, cross-platform app development, UI/UX
            design, and app maintenance & support.
          </p>
        </div>
      </div>

      <div className="upcards">
        <AppCards />
      </div>

      <div className="main-container container">
        <h2>Affordable App Development Services</h2>
        <h5 className="last-paragraph">
          Get premium app development services in Dubai at competitive rates.
          Whether you need a simple app or a complex solution, we’ve got you
          covered—your success, our priority.
        </h5>
      </div>

      <div className="plans-container container">
        <PricingCards pricingPlans={plans} />
        {/* {plans.map((plan, index) => (
          <Card className={`plan-card plan-card-${index}`} key={index}>
            <CardActionArea>
              <CardContent>
                <div className="plans-cont">
                  <div className="plan-header">
                    <span className="plan-title">{plan.name}</span>
                    <button
                      className="select-button"
                      onClick={() => handleShow(plan.name)}
                      aria-label={`Select ${plan.name} plan`} 
                    >
                      START YOUR PLAN
                    </button>
                  </div>
                  <div className="plan-features-container">
                    <ul className="plan-features">
                      {plan.features.map((feature, i) => (
                        <li key={i}>
                          <i className="fa fa-check-circle"></i> {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </CardContent>
            </CardActionArea>
          </Card>
        ))} */}
        <button
          className="contact-btn"
          style={{ padding: "20px" }}
          onClick={() => navigate("/portfolio-detail/app/App%20Development")}
        >
          Explore Our Portfolio of Mobile Applications
        </button>
      </div>

      {/* Conditionally render modal based on selected plan type */}
      {showModal.open && (
        <>
          {showModal.type === "Basic" && (
            <ModalformBasicApp
              isOpened={showModal.open}
              heading={`${selectedPlan} Plan`}
              handleClose={handleCloseModal}
            />
          )}
          {showModal.type === "Standard" && (
            <ModalformStandardApp
              isOpened={showModal.open}
              heading={`${selectedPlan} Plan`}
              handleClose={handleCloseModal}
            />
          )}
          {showModal.type === "Premium" && (
            <ModalformPremiumApp
              isOpened={showModal.open}
              heading={`${selectedPlan} Plan`}
              handleClose={handleCloseModal}
            />
          )}
        </>
      )}

      <ConsultationBanner
        heading={"Ready to Build Your Dream App?"}
        para={
          "Partner with Softmark Solutions, the leading mobile app development company in the UAE. Contact us today to unlock innovative app development services tailored to your business."
        }
      />
      <WebAgency
        heading={`Why Choose Softmark Solutions for App Development?`}
        para1={`At Softmark Solutions, we specialize in creating apps that align with your goals. Whether you need Android application development in Dubai or cross-platform solutions, we deliver excellence.`}
        para2={`Our team is skilled in building intuitive, user-friendly apps that engage audiences and drive results. From initial concept to launch and ongoing support, we ensure your app stands out in the competitive market of mobile app development in Dubai.`}
      />
      <MoreServices
        heading={"Expand Your Digital Horizon with Softmark Solutions"}
        para={`Explore our full range of services, including Android app development, iOS app development in Dubai, mobile app design, and maintenance. At Softmark Solutions, we empower businesses to grow with innovative app solutions.`}
      />
      <ContactForm
        heading={"Let Softmark Solutions Bring Your App Ideas to Life"}
        para={`Looking to work with the best mobile app development company in the UAE? Contact Softmark Solutions today. We make app development simple, effective, and affordable. Drop us a message now!`}
      />
    </>
  );
}

PlansApp.propTypes = {
  plans: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      features: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ).isRequired,
};
