import React from 'react'
import '../css/Cards.css'
import Singlecard from './Singlecard'
import BlogWriting from '../assets/icons/blog.png'
import WebsiteContent from '../assets/icons/contentWriting.png'
import Copywriting from '../assets/icons/copy.png'
import ProductDescriptions from '../assets/icons/product.png'
import SocialMediaContent from '../assets/icons/social-media.png'
import EditingAndProofreading from '../assets/icons/proofreading-tools.png'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"; // Slick CSS
import "slick-carousel/slick/slick-theme.css"; // Slick Theme CSS

const ContentWritingCards = ({ heading }) => {
    const ContentWriting = [
        {
          path: BlogWriting,
          title: 'SEO Blog Writing Services',
          desc: 'Attract readers and improve your search engine rankings with well-researched and engaging blog content. Our writers specialize in crafting blogs that not only inform but also align with your business goals and target audience.'
        },
        {
          path: WebsiteContent,
          title: 'Website Content Writing',
          desc: 'Transform your website into a conversion powerhouse with compelling and professional content. We focus on creating clear, engaging, and SEO-optimized copy for homepages, about pages, service pages, and more.'
        },
        {
          path: Copywriting,
          title: 'Persuasive Copywriting Services',
          desc: 'Connect with your audience and boost conversions with copy that sells. Whether it’s for email campaigns, landing pages, or advertisements, our copywriting ensures your message is impactful and action-oriented.'
        },
        {
          path: ProductDescriptions,
          title: 'Product Description Writing',
          desc: 'Turn your products into must-haves with creative and detailed product descriptions. We highlight unique features and benefits, helping you engage customers and drive purchases effectively.'
        },
        {
          path: SocialMediaContent,
          title: 'Social Media Content Writing',
          desc: 'Capture attention and increase engagement on platforms like Instagram, Facebook, and LinkedIn. Our social media content is tailored to your brand voice and designed to spark conversations and drive interaction.'
        },
        {
          path: EditingAndProofreading,
          title: 'Content Editing & Proofreading Services',
          desc: 'Ensure your content is flawless and impactful with our editing and proofreading services. We refine your drafts, correct errors, and optimize readability to present your message professionally.'
        }
      ];
      
      
      const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      };
    

  return (
    <div>
      <div className='card-heading-service' id='services'>
        <h2 className='heading-underline-service' id='services'>
          {heading}
        </h2>
      </div>
      <div className='cards-container container'>
      <Slider
          {...settings}
          style={{
            width: "100%",
            margin: "0 auto", // Centers the slider in the container
          }}>
        {ContentWriting.map((item, index) => (
          <Singlecard
            key={index}
            path={item.path}
            title={item.title}
            description={item.desc}
            route={item.route}
          />
        ))}
        </Slider>
      </div>
    </div>
  )
}

export default ContentWritingCards
