import React, { useEffect } from "react";
import Typewriter from "../components/TypeWriter.jsx";
import "../css/CSD.css";
import CSD_Cards from "../components/CustomSoftwareDev.jsx";
import { useNavigate } from "react-router-dom";
import Csd_Steps from "./CSD_steps.jsx";
import SoftwareCapabilitiesCards from "../components/SoftwareCapabilitiesCards.jsx";
import SoftwareCards from "../components/SoftwareCards.jsx";
import CSDLottieAnimation from "../components/CSD-Animation.jsx";
import MoreServices from "../components/moreServices/moreServices.jsx";
import ServiceForm from "../components/ServiceForm.jsx";
import ContactForm from "../components/ContactForm.jsx";
import PricingCards from "../components/pricingCards/priceCards.jsx";
import ConsultationBanner from "../components/consultationBanner/consultationBanner.jsx";
import WebAgency from "../components/webAgency/webAgency.jsx";

const CustomSoftware = ({ plans }) => {
  const navigate = useNavigate();

  return (
    <>
      <ServiceForm
        title={
          "Professional Software Development Services for Modern Businesses"
        }
        description={
          "Softmark Solutions is a leading software development company offering innovative, custom solutions. Elevate your business with our reliable software development services in Dubai."
        }
        buttonText={"Get In Touch"}
      />

      {/* Section 1 */}
      <div className="main-container container">
        <h2>
          <Typewriter
            strings={["Tailored Solutions for Your Business Needs"]}
          />
        </h2>
        <div className="horizontal-fram-logo">
          <div className="csd-hero">
            <h5 className="main-paragrah-csd main-paragrah">
              At SoftMark Solutions, we understand that every business is
              unique. That’s why we offer custom software development services
              tailored to meet your specific requirements. Whether you’re a
              small business or a large enterprise, our team of expert
              developers will work closely with you to create software solutions
              that drive efficiency, innovation, and growth.
              <br />
              <div class="buttons">
                <button class="btn" onClick={() => navigate("/contactUs")}>
                  <span></span>
                  <p data-text="Get Proposal" data-title="Contact Now"></p>
                </button>
              </div>
            </h5>
            <div className="CSD-animation">
              <CSDLottieAnimation />
            </div>
          </div>
        </div>
      </div>
      <Csd_Steps />
      <CSD_Cards />

      {/* Section 2 */}
      <SoftwareCapabilitiesCards />
      <SoftwareCards />

      <div className="main-container container">
        <h2>Flexible and Affordable Software Development Packages</h2>
        <h5 className="last-paragraph">
          Get custom software development in Dubai at competitive prices.
          Softmark Solutions delivers high-quality software tailored to your
          business needs without exceeding your budget.
        </h5>
      </div>

      <div className="plans-container container">
        <PricingCards pricingPlans={plans} />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <button
          className="contact-btn"
          style={{ padding: "20px" }}
          onClick={() =>
            navigate("/portfolio-detail/custom/Custom%20Development")
          }
        >
          Explore Our Custom Software Development Portfolio{" "}
        </button>
      </div>
      <ConsultationBanner
        heading={"Build Smarter Solutions with Softmark Solutions"}
        para={`Softmark Solutions, a trusted software development agency, is here to craft custom solutions for your unique business needs. Partner with the leading software development company today!`}
      />
      <WebAgency
        heading={`Why Choose Softmark Solutions for Software Development?`}
        para1={`As a top-rated software house in Dubai, Softmark Solutions specializes in delivering custom software development services designed to enhance business performance. Whether you need enterprise applications or custom tools, our expert team ensures seamless execution.`}
        para2={`We pride ourselves on being an IT software development company that delivers innovative solutions. With a focus on quality, efficiency, and scalability, Softmark Solutions is the ideal partner for businesses seeking dependable software development services in Dubai.`}
      />
      <MoreServices
        heading={"Explore More Services by Softmark Solutions"}
        para={`Softmark Solutions offers a range of services beyond software development. Our expertise includes Website Development Services, App Development Services, Graphics Design Services, SEO Services, IT Outsourcing, Anime Art Services, Social Media Marketing Services, and Content Writing Services.
From technical expertise to creative innovation, Softmark Solutions is your one-stop destination for business growth. Discover how our diverse offerings can support your success.

`}
      />
      <ContactForm
        heading={"Let Softmark Solutions Develop Software for Your Business"}
        para={`Looking for a reliable software development agency? Contact Softmark Solutions today for custom software development in Dubai. Let us build innovative solutions tailored to your business goals!`}
      />
    </>
  );
};

export default CustomSoftware;
