import React from 'react';
import '../css/workingProcess.css';
import working from '../assets/images/working.webp';

import icon1 from '../assets/images/wor-icon1.webp'
import icon2 from '../assets/images/wor-icon2.webp'
import icon3 from '../assets/images/wor-icon3.webp'

const WorkingProcess = () => {
  return (
    <div className='main-workingProcess' style={{ padding: '', backgroundColor: '#f2f9ff' }}>
      {/* Section 1: Cards */}
      <div className='workingCards'>
        
        <div className='cards-container container ' style={{ display: 'grid', padding:'4rem', gap: '1rem', gridTemplateColumns: '1fr',  }}>
        {/* Card 1 */}
        <h4 className='container' style={{ textAlign: 'left', color: 'rgb(220, 166, 66)' }}>Our Working Process</h4>
        <h2 className='container' style={{ color: '#4599b4', fontWeight: 'bold' }}>
        Our Proven Process for <br /> Digital Marketing Success 
        </h2>
        <div className='working-card' style={{ display: 'flex', gap: '1rem', padding: '2rem', backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 4px 6px rgba(0,0,0,0.1)' }}>
          <div style={{ width: '50%',  display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img src={icon1} alt="Consultation Icon" style={{ width: '60%' }} />
          </div>
          <div >
            <h4  style={{ color: '#4599b4', fontWeight: 'bold' }}>01. Consultation & Goal  <br /> Alignment</h4>
            <p style={{ color: 'gray',textAlign:'left', fontSize:'1rem' }}>
            We start with an in-depth consultation to understand your business, objectives, and target market. Our digital marketing agency in Dubai takes the time to align on goals and create a strong foundation for your strategy.
            </p>
          </div>
        </div>

        {/* Card 2 */}
        <div className='working-card' style={{ display: 'flex', gap: '1rem', padding: '2rem', backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 4px 6px rgba(0,0,0,0.1)' }}>
          <div style={{ width: '50%',  display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img src={icon2} alt="Strategy Icon" style={{ width: '60%' }} />
          </div>
          <div>
            <h4 style={{ color: '#4599b4', fontWeight: 'bold', }}>02.  Customized Strategy &  <br /> Execution</h4>
            <p style={{ color: 'gray',textAlign:'left',fontSize:'1rem'  }}>
            Using insights from the initial consultation, our team develops a tailored digital marketing plan, covering SEO, content marketing, and social media. We implement each step strategically to ensure measurable growth.
            </p>
          </div>
        </div>

        {/* Card 3 */}
        <div className='working-card' style={{ display: 'flex', gap: '1rem', padding: '2rem', backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 4px 6px rgba(0,0,0,0.1)' }}>
          <div style={{ width: '50%',  display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img src={icon3} alt="Analysis Icon" style={{ width: '60%' }} />
          </div>
          <div>
            <h4 style={{ color: '#4599b4', fontWeight: 'bold',}}>03. Performance Monitoring & Optimization</h4>
            <p style={{ color: 'gray',textAlign:'left',fontSize:'1rem'  }}>
            We monitor your campaign’s performance using advanced analytics, allowing us to refine strategies for maximum impact. Our continuous improvement approach keeps your business ahead in the competitive digital landscape.
            </p>
          </div>
        </div>
      </div>
      </div>
      

      {/* Section 2: Image */}
      <div className='hide-img' >
        <img src={working} style={{ width: '100%', height:'100%', borderRadius: '8px', }} alt="Working team" />
      </div>
    </div>
  );
};

export default WorkingProcess;
