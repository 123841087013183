import React from "react";
import "../../css/webAgency.css";
import Img from "../../assets/images/webAgency.jpg";
import ConsultationBtn from "../Buttons/ConsultationBtn";

const WebAgency = ({heading, para1, para2}) => {
  return (
    <div className="who-we-are-section">
      <div className="about-us-container">
        <div className="text-container">
          <h2 style={{ textAlign: "left" }}>
           {heading}
          </h2>
          <p style={{ textAlign: "left" }}>
            {para1}
            <br/>
            {para2}
          </p>
          <div style={{ marginTop: "-20px" }}>
            <ConsultationBtn text="Get in touch" />
          </div>
        </div>
        <div className="image-container">
          <img
            src="https://img.freepik.com/premium-photo/medium-shot-man-working-laptop_23-2150323514.jpg?w=826"
            alt="Who we are"
            className="about-us-image"
          />
        </div>
      </div>
    </div>
  );
};

export default WebAgency;
