import * as React from "react";
import { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardActionArea } from "@mui/material";
import "../css/logo.css";
import ModalformBasicLogo from "../components/Modal/logo-design/Modalbasic";
import ModalformStandardLogo from "../components/Modal/logo-design/Modalstandard";
import ModalformBusiness from "../components/Modal/logo-design/Modalbusniess";
import ModalformPremium from "../components/Modal/logo-design/Modalpremium";
import LDLottieAnimation from "../components/LogoDesignAnimation";
import Typewriter from "../components/TypeWriter.jsx";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import ConsultationBanner from "../components/consultationBanner/consultationBanner.jsx";
import MoreServices from "../components/moreServices/moreServices.jsx";
import PricingCards from "../components/pricingCards/priceCards.jsx";
import ServiceForm from "../components/ServiceForm.jsx";
import ContactForm from "../components/ContactForm.jsx";
import WebAgency from "../components/webAgency/webAgency.jsx";
import GraphicDesignCards from "../components/GraphicDesignCards.jsx";

export default function Logo({ plans }) {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState({ open: false, type: "" });
  const [selectedPlan, setSelectedPlan] = useState("");

  const handleCloseModal = () => setShowModal({ open: false, type: "" });

  const handleShow = (planName) => {
    setSelectedPlan(planName);
    setShowModal({ open: true, type: planName.trim() });
  };

  return (
    <>
      <ServiceForm
        title={"Professional Graphics Design Services for Your Brand"}
        description={
          "Elevate your visual identity with Softmark Solutions. Our custom graphic design services deliver stunning logos, visuals, and more to make your brand stand out."
        }
        buttonText={"Get Your Logo"}
      />

      <div className="main-container container">
        <h2>
          <Typewriter
            strings={["Transform Your Brand with SoftMark Solutions"]}
          />
        </h2>
        <div className="horizontal-fram-logo">
          <div style={{ width: "100%" }}>
            <h5 className="main-paragrah">
              At SoftMark Solutions, we specialize in creating stunning logo
              designs and comprehensive branding packages that set your business
              apart. Our team of expert designers is dedicated to bringing your
              vision to life, ensuring your brand resonates with your audience
              and leaves a lasting impression. Explore our plans below to find
              the perfect fit for your branding needs.
            </h5>
          </div>

          <div className="logo-animation">
            <LDLottieAnimation />
          </div>
        </div>
      </div>

      <div className="card-heading-service">
        <h2 className="heading-underline-service">
          Exceptional Graphics Design Services Tailored to You
        </h2>
        <div className="container text" style={{ marginTop: "2rem" }}>
          <p>
            Softmark Solutions offers custom graphic design services, logo
            design services, branding, marketing collateral design, and UI/UX
            graphics. Trust the leading graphic design agency for stunning
            visuals.
          </p>
        </div>
      </div>

      <div className="upcards">
        <GraphicDesignCards />
      </div>

      <div className="main-container container">
        <h2>Affordable Graphics Design Services</h2>
        <h5 className="last-paragraph">
          Unlock premium graphics design services at Softmark Solutions. Whether
          it’s logo design or full branding, we provide creative solutions at
          budget-friendly rates.
        </h5>
      </div>

      <div className="plans-container container">
        <PricingCards pricingPlans={plans} />
        {/* {plans.map((plan, index) => (
          <Card className={`plan-card plan-card-${index} logoPlans`} key={index}>
            <CardActionArea>
              <CardContent>
                <div className="plans-cont">
                  <div className="plan-header">
                    <span className="plan-title">{plan.name}</span>
                    <button
                      className="select-button"
                      onClick={() => handleShow(plan.name)}
                      aria-label={`Select ${plan.name} plan`}
                    >
                      START YOUR PLAN
                    </button>
                  </div>
                  <div className="plan-features-container">
                    <ul className="plan-features">
                      {plan.features.map((feature, i) => (
                        <li key={i}>
                          <i className="fa fa-check-circle"></i> {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </CardContent>
            </CardActionArea>
          </Card>

        ))} */}
        <button
          className="contact-btn"
          onClick={() => navigate("/portfolio-detail/logo/Logo%20Development")}
        >
          Discover Our Logo Design Portfolio{" "}
        </button>
      </div>

      {showModal.open && (
        <>
          {showModal.type === "Basic" && (
            <ModalformBasicLogo
              isOpened={showModal.open}
              heading={`${selectedPlan} Plan`}
              handleClose={handleCloseModal}
            />
          )}
          {showModal.type === "Standard" && (
            <ModalformStandardLogo
              isOpened={showModal.open}
              heading={`${selectedPlan} Plan`}
              handleClose={handleCloseModal}
            />
          )}
          {showModal.type === "Business" && (
            <ModalformBusiness
              isOpened={showModal.open}
              heading={`${selectedPlan} Plan`}
              handleClose={handleCloseModal}
            />
          )}
          {showModal.type === "Premium" && (
            <ModalformPremium
              isOpened={showModal.open}
              heading={`${selectedPlan} Plan`}
              handleClose={handleCloseModal}
            />
          )}
        </>
      )}

      <ConsultationBanner
        heading={"Transform Your Brand with Stunning Designs"}
        para={`Partner with Softmark Solutions, a trusted graphics design company. Contact us today to access top-tier graphic design services that will make your business shine.`}
      />
      <WebAgency
        heading={`Why Choose Softmark Solutions for Graphics Design?`}
        para1={`At Softmark Solutions, we specialize in custom graphic design services that capture your brand’s essence. Whether you need logos, marketing materials, or complete branding, we deliver creativity that speaks volumes.`}
        para2={`Our team combines innovation and expertise to create designs that resonate with your audience. As a leading graphic design agency, we ensure every project is unique, impactful, and aligned with your business goals.`}
      />
      <MoreServices
        heading={
          "Comprehensive Graphics Design Solutions at Softmark Solutions"
        }
        para={`Explore a wide range of services, including logo design services, branding, UI/UX design, and more. Softmark Solutions is your go-to graphics design company for creative excellence.`}
      />
      <ContactForm
        heading={"Get in Touch with Softmark Solutions for Graphics Design"}
        para={`Need custom graphic design services to elevate your business? Contact Softmark Solutions today. From logos to marketing materials, we make your vision a reality. Let’s create something amazing!`}
      />
    </>
  );
}

Logo.propTypes = {
  plans: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      features: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ).isRequired,
};
