import React, { useState } from "react";
import { Box, Grid, Typography, IconButton } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import TinderCardSlider from "../react-tinder-card/tinderCard";

const images = [
  "https://via.placeholder.com/300x400?text=Image+1",
  "https://via.placeholder.com/300x400?text=Image+2",
  "https://via.placeholder.com/300x400?text=Image+3",
];

const MarketingSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <Box
      sx={{
        padding: { xs: 2, sm: 4, md: 8 },
        backgroundColor: "#f5f5f5",
        borderRadius: 2,
        // paddingTop:'-100px'
      }}
      
    >
      <Grid container spacing={4} alignItems="center">
        {/* Left Side: Text Content */}
        <Grid item xs={12} md={6}>
        <Typography
            variant="h4"
            component="h5"
            gutterBottom
            textAlign="left"
            color="rgb(220, 166, 66)"
            className="container"
            
          >
            Testimonials
          </Typography>
          <Typography
            variant="h4"
            component="h2"
            gutterBottom
            textAlign="left"
            color="#4599B4"
            fontWeight="bold"
            className="container"
          >
            What Our Clients Say?
          </Typography>
          <Typography className="container" variant="body1" paragraph textAlign="left">
          Our clients value us as a digital marketing agency in Dubai that truly understands their needs. They trust our commitment to delivering tailored, impactful strategies that drive real results. Every testimonial reflects our dedication to helping businesses grow, and the positive feedback fuels our passion for excellence in digital marketing.
          </Typography>
          
        </Grid>

        {/* Right Side: Custom Tinder-like Slider */}
        <Grid item xs={12} md={6} sx={{ position: "relative" }}>
          <TinderCardSlider />
        </Grid>
      </Grid>
    </Box>
  );
};

export default MarketingSection;
