import React from "react";
import "../css/ItOutsourcing.css";
import Typewriter from "../components/TypeWriter.jsx";
import { useNavigate } from "react-router-dom";
import DMLottieAnimation from "../components/DigitalMarketingAnimation";
import ItOutTopCards from "../components/ItOutTopCards.jsx";
import ItOut_Steps from "../components/ItoutSteps.jsx";
import ItOutNotch from "../ItOutNotch.jsx";
import ItOutMidcards from "../components/ItOutMidcards.jsx";
import ItOutBottomCards from "../components/ItOutBottomCards.jsx";
import BarChart from "../components/BarChart.jsx";
import MoreServices from "../components/moreServices/moreServices.jsx";
import ServiceForm from "../components/ServiceForm.jsx";
import ContactForm from "../components/ContactForm.jsx";
import PricingCards from "../components/pricingCards/priceCards.jsx";
import ConsultationBanner from "../components/consultationBanner/consultationBanner.jsx";
import WebAgency from "../components/webAgency/webAgency.jsx";

const ItOutsourcing = ({ plans }) => {
  const navigate = useNavigate();
  return (
    <>
      <ServiceForm
        title={
          "Reliable IT Outsourcing Company for Seamless Business Operations"
        }
        description={
          "Softmark Solutions is a trusted IT outsourcing company delivering top-tier IT solutions to businesses in Dubai and Abu Dhabi. Let us handle your IT needs while you focus on growth."
        }
        buttonText={"Get In Touch"}
      />

      <div className="main-container container">
        <h2>
          <Typewriter strings={["#1 IT Outsourcing Services Provider"]} />
        </h2>
        <div className="horizontal-fram-logo">
          <div className="csd-hero">
            <h5 className="main-paragrah-csd main-paragrah">
              Softmark solutions gives your business access to a global talent
              pool. Our highly skilled IT professionals can address the needs
              and challenges of your business. Whether you are looking for Web
              development, Mobile app development, Digital Marketing , SEO
              services, Anime Art, Logo design look no further than Softmark
              Solutions.
              <br />
              <div class="buttons">
                <button class="btn" onClick={() => navigate("/contactUs")}>
                  <span></span>
                  <p data-text="Get Proposal" data-title="Contact Now"></p>
                </button>
              </div>
            </h5>
            <div className="CSD-animation">
              <DMLottieAnimation />
            </div>
          </div>
        </div>
      </div>
      <div className="sub-heading container">
        <h1>Comprehensive IT Outsourcing Services for Businesses</h1>
        <p className="intro-text sub-text">
          Softmark Solutions specializes in IT outsourcing in Abu Dhabi and
          Dubai. From IT support and infrastructure management to cloud
          solutions and cybersecurity, we are the go-to IT services company in
          Dubai.
        </p>
      </div>

      <div className="main-container container">
        <h2>Flexible & Affordable IT Outsourcing Solutions</h2>
        <h5 className="last-paragraph">
          Choose Softmark Solutions for cost-effective IT outsourcing services.
          Our customizable plans ensure you get the support you need within your
          budget, enhancing your business performance.
        </h5>
      </div>

      <div className="plans-container container">
        <PricingCards pricingPlans={plans} />
      </div>

      <ItOutTopCards />
      <div className="sub-heading container">
        <h1>We Deliver Results!</h1>
        <p className="intro-text sub-text">
          Our goal at Softmark Solutions is to ensure transparency,
          cost-effectiveness, and seamless collaboration throughout the
          outsourcing process. We are committed to delivering high-quality
          results that not only meet your expectations but also propel your
          business forward.
        </p>
        {/* <div >
        <BarChart/>
      </div> */}
      </div>
      <ItOut_Steps />
      <ItOutNotch />
      <ItOutMidcards />
      <ItOutBottomCards />
      <div className="sub-heading container">
        <h1 className="custom-mob">
          Why Choose Softmark Solutions Over Other <br /> IT Outsourcing
          Companies Across The Globe
        </h1>
        <p className="intro-text sub-text">
          Choose Softmark Solutions as your preferred IT outsourcing partner for
          a competitive edge in the global market. Our deep industry knowledge
          and customized solutions make us stand out. By collaborating with our
          team, you can expect top-tier results that consistently surpass your
          expectations. With Softmark Solutions, you're not just outsourcing;
          you're forming a partnership grounded in innovation, reliability, and
          a mutual commitment to achieving outstanding outcomes.
        </p>
      </div>
      <div
        className="button-effect"
        style={{
          paddingTop: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={() => navigate("/whyUs")}
      >
        <a className="effect effect-1" href="#whyus" title="Learn More">
          Why Us
        </a>
      </div>
      <ConsultationBanner
        heading={"Streamline Your IT with Softmark Solutions"}
        para={`Partner with Softmark Solutions, a leading IT outsourcing company in Dubai. From expert consultants to cutting-edge IT providers, we’re here to manage your IT infrastructure efficiently. Contact us now!`}
      />
      <WebAgency
        heading={`Why Choose Softmark Solutions as Your IT Outsourcing Partner?`}
        para1={`Softmark Solutions stands out as a trusted IT outsourcing company in Dubai and Abu Dhabi. Our expertise lies in providing end-to-end IT services, from infrastructure management to technical support, ensuring your operations run smoothly.`}
        para2={`As one of the leading IT providers in Dubai, we prioritize customized solutions that align with your business goals. Whether you’re looking for expert Dubai outsourcing consultants or reliable IT services company support, Softmark Solutions is your partner for growth and innovation.`}
      />
      <MoreServices
        heading={"Discover Our Complete Range of Services"}
        para={`Softmark Solutions offers more than just IT outsourcing services. Explore our wide array of services, including Website Development Services, App Development Services, Graphics Design Services, SEO Services, Software Development Services, Anime Art Services, Social Media Marketing Services, and Content Writing Services.
From managing your IT infrastructure to delivering creative and technical solutions, Softmark Solutions is your one-stop destination for all your business needs. Trust us to provide reliable and efficient services tailored to your goals.`}
      />
      <ContactForm
        heading={"Let Softmark Solutions Handle Your IT Outsourcing Needs"}
        para={`Looking for expert IT providers in Dubai? Contact Softmark Solutions today for reliable and cost-effective IT outsourcing in Abu Dhabi and Dubai. Let us streamline your operations for maximum efficiency!`}
      />
    </>
  );
};

export default ItOutsourcing;
