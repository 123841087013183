import React from 'react'
import '../css/Cards.css'
import Singlecard from './Singlecard'
import LogoDesign from '../assets/icons/logoDesign.png'
import Branding from '../assets/icons/brandDesign.png'
import PrintDesign from '../assets/icons/printing.png'
import SocialMedia from '../assets/icons/socialMedia.png'
import WebGraphics from '../assets/icons/graphicDesign.png'
import Packaging from '../assets/icons/packageDesign.png'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const GraphicDesignCards = ({ heading }) => {
    const DesignData = [
        {
          path: LogoDesign,
          title: 'Logo Design',
          desc: 'Creating memorable and unique logos that reflect your brand identity. We combine creativity and strategy to design logos that leave a lasting impression.'
        },
        {
          path: Branding,
          title: 'Branding & Identity',
          desc: 'Crafting cohesive branding solutions that resonate with your audience. From color schemes to typography, we ensure consistency across all platforms.'
        },
        {
          path: PrintDesign,
          title: 'Print Design',
          desc: 'Designing high-quality print materials like brochures, business cards, and posters that captivate attention and communicate effectively.'
        },
        {
          path: SocialMedia,
          title: 'Social Media Graphics',
          desc: 'Designing eye-catching social media visuals tailored to your brand. We ensure your content stands out and engages audiences across platforms.'
        },
        {
          path: WebGraphics,
          title: 'Web & Digital Graphics',
          desc: 'Delivering stunning visuals for websites, banners, and ads that enhance user engagement and align with your digital marketing goals.'
        },
        {
          path: Packaging,
          title: 'Packaging Design',
          desc: 'Creating visually appealing and functional packaging that highlights your product and attracts customers on the shelf.'
        }
      ];
      
      
      const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      };
    

  return (
    <div>
      <div className='card-heading-service' id='services'>
        <h2 className='heading-underline-service' id='services'>
          {heading}
        </h2>
      </div>
      <div className='cards-container container'>
      <Slider
          {...settings}
          style={{
            width: "100%",
            margin: "0 auto", 
          }}>
        {DesignData.map((item, index) => (
          <Singlecard
            key={index}
            path={item.path}
            title={item.title}
            description={item.desc}
            route={item.route}
          />
        ))}
        </Slider>
      </div>
    </div>
  )
}

export default GraphicDesignCards
