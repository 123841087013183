const WebPlans = [
  {
    name: " Basic ",
    price: "$20",
    features: [
      "Functional website",
      "1 page",
      "5 plugins/extensions",
      "Payment processing",
      "Hosting setup",
    ],
  },
  {
    name: "Standard",
    price: "$50",
    features: [
      "Functional website",
      "5 page",
      "Content upload",
      "8 plugins/extensions",
      "E-commerce functionality",
      "5 products",
      "Payment processing",
      "Speed optimization",
      "Hosting setup",
      "Social media icons",
    ],
  },
  {
    name: "Premium",
    price: "$100",
    features: [
      "Functional website",
      "10 page",
      "Content upload",
      "8 plugins/extensions",
      "E-commerce functionality",
      "10 products",
      "Payment processing",
      "Opt-in form",
      "Autoresponder integration",
      "Speed optimization",
      "Hosting setup",
      "Social media icons",
    ],
  },
];

export const SeoPlans = [
  {
    name: "03-month Plan",
    price: "$20",
    features: [
      "Basic keyword research",
      "On-page optimization",
      "Monthly performance reports",
      "Limited off-page optimization",
      "Suitable for business looking for a short-term SEO boost",
      "Perfect for startup and small business on a small budget",
    ],
  },
  {
    name: "06-month Plan",
    price: "$50",
    features: [
      "Comprehensive keyword research",
      "On-page and off-page optimization",
      "Content creation and optimization",
      "Competitor analysis",
      "Monthly analytics reporting",
      "Ideal for businesses seeking sustainable SEO growth",
      "Suitable for medium-sized business looking for a mid-term SEO solution",
    ],
  },
  {
    name: "12-month Plan",
    price: "$100",
    features: [
      "Advanced keyword research and optimiation",
      "On-page, off-page and technical SEO",
      "Regular content updates and optimization",
      "Ongoing competitor analysis and strategy refinement",
      "Detailed monthly performance tracking and reporting",
      "Designed for long-term SEO success and maximum ROI",
      "Recommended for larger enterprises and businesses with aggressive growth goals",
    ],
  },
];

export const SocialMediaPlans = [
  {
    name: "Basic",
    price: "$30",
    features: [
      "Basic content creation and scheduling",
      "Profile optimization for up to 2 platforms",
      "Monthly performance reports",
      "Engagement monitoring and response",
      "Ideal for startups or small businesses testing social media marketing",
    ],
  },
  {
    name: "Standard",
    price: "$70",
    features: [
      "Custom content strategy and creation",
      "Management of up to 3 platforms",
      "Monthly analytics and insights reporting",
      "Campaign setup and execution",
      "Audience growth and engagement strategies",
      "Suitable for growing businesses seeking consistent social presence",
    ],
  },
  {
    name: "Premium",
    price: "$150",
    features: [
      "Comprehensive social media strategy",
      "Management of up to 5 platforms",
      "Advanced analytics and ROI tracking",
      "Targeted ad campaigns and budget optimization",
      "Regular content updates, contests, and promotions",
      "Perfect for businesses focused on long-term growth and brand building",
    ],
  },
];


export const LogoPlans = [
  {
    name: " Basic ",
    price: "$20",
    features: [
      "Includes 1 logo concept",
      "Logo transparency",
      "Vector file",
      "pritable file",
    ],
  },
  {
    name: "Standard",
    price: "$50",
    features: [
      "Includes 3 logo concept",
      "Logo transparency",
      "Vector file",
      "pritable file",
      "3D mockup",
      "Source file",
      "Social media kit",
    ],
  },
  {
    name: "Premium",
    price: "$100",
    features: [
      "Includes 3 logo concept",
      "Logo transparency",
      "Vector file",
      "pritable file",
      "3D mockup",
      "Source file",
    ],
  },
  {
    name: "Business",
    price: "$200",
    features: [
      "Includes 5 logo concept",
      "Logo transparency",
      "Vector file",
      "pritable file",
      "3D mockup",
      "Source file",
      "Stationary designs",
      "Social media kit",
      "Product design",
    ],
  },
];

export const AppPlans = [
  {
    name: " Basic ",
    price: "$20",
    features: [
      "Functional Android app",
      "Functional IOS app",
      "App Design",
      "App Submission",
      "App icon",
      "Splash screen",
    ],
  },
  {
    name: "Standard",
    price: "$50",
    features: [
      "Functional Android app",
      "Functional IOS app",
      "App Design",
      "App Submission",
      "App icon",
      "Splash screen",
      "Ad network integration",
    ],
  },
  {
    name: "Premium",
    price: "$100",
    features: [
      "Functional Android app",
      "Functional IOS app",
      "App Design",
      "App Submission",
      "App icon",
      "Splash screen",
      "Ad network integration",
      "Source Code",
    ],
  },
];

export const SMMPlans = [
  {
    name: "Basic",
    price: "$50",
    features: [
      "Social media account setup",
      "Content posting (3 posts/week)",
      "Basic graphic design for posts",
      "1 social media platform",
      "Performance tracking report",
    ],
  },
  {
    name: "Standard",
    price: "$100",
    features: [
      "Social media account setup",
      "Content posting (5 posts/week)",
      "Custom graphic design for posts",
      "2 social media platforms",
      "Performance tracking report",
      "Basic audience engagement",
    ],
  },
  {
    name: "Premium",
    price: "$200",
    features: [
      "Social media account setup & optimization",
      "Content posting (7 posts/week)",
      "Custom graphic and video content",
      "3 social media platforms",
      "Performance tracking & analytics report",
      "Advanced audience engagement",
      "Ad campaign setup & management",
    ],
  },
];

export const SEOPlans = [
  {
    name: "Basic",
    price: "$100",
    features: [
      "Website audit & analysis",
      "Keyword research (5 keywords)",
      "On-page SEO optimization",
      "Meta tags optimization",
      "Monthly performance report",
      "Basic technical SEO fixes",
    ],
  },
  {
    name: "Standard",
    price: "$200",
    features: [
      "Website audit & analysis",
      "Keyword research (10 keywords)",
      "On-page & off-page SEO optimization",
      "Meta tags & content optimization",
      "Monthly performance & ranking report",
      "Technical SEO improvements",
      "Internal linking strategy",
    ],
  },
  {
    name: "Premium",
    price: "$400",
    features: [
      "Comprehensive website audit & analysis",
      "Keyword research (20+ keywords)",
      "On-page & off-page SEO optimization",
      "High-quality backlink building",
      "Content creation & optimization",
      "Advanced technical SEO",
      "Competitor analysis",
      "Local SEO optimization",
      "Weekly performance & ranking report",
    ],
  },
];

export const ITOutsourcingPlans = [
  {
    name: "Basic",
    price: "$300",
    features: [
      "IT helpdesk support (8 hours/day)",
      "Basic troubleshooting",
      "Software installation & updates",
      "System performance monitoring",
      "Monthly maintenance report",
    ],
  },
  {
    name: "Standard",
    price: "$600",
    features: [
      "24/7 IT helpdesk support",
      "Advanced troubleshooting",
      "Software & hardware maintenance",
      "Network monitoring & management",
      "Data backup & recovery",
      "Monthly performance & issue report",
    ],
  },
  {
    name: "Premium",
    price: "$1,200",
    features: [
      "Dedicated IT support team",
      "24/7 IT helpdesk & onsite support",
      "Network & server management",
      "Cybersecurity monitoring & threat prevention",
      "Cloud infrastructure management",
      "Data backup, recovery, and security",
      "Weekly performance & analytics reports",
    ],
  },
];

export const SoftwareDevPlans = [
  {
    name: "Basic",
    price: "$1,000",
    features: [
      "Custom software consultation",
      "Requirements analysis",
      "Basic UI/UX design",
      "Small-scale software development",
      "1 platform (Web/Desktop)",
      "Quality assurance & testing",
      "Project delivery within 2-4 weeks",
    ],
  },
  {
    name: "Standard",
    price: "$3,000",
    features: [
      "Comprehensive project consultation",
      "Detailed requirements analysis",
      "UI/UX design with revisions",
      "Medium-scale software development",
      "Multi-platform compatibility (Web/Desktop)",
      "API integration",
      "Quality assurance & testing",
      "Project delivery within 4-8 weeks",
    ],
  },
  {
    name: "Premium",
    price: "$6,000",
    features: [
      "End-to-end project management",
      "In-depth requirements analysis & planning",
      "Advanced UI/UX design with multiple revisions",
      "Large-scale custom software development",
      "Cross-platform compatibility (Web/Desktop/Mobile)",
      "Advanced API & third-party integrations",
      "Ongoing maintenance & support",
      "Quality assurance, testing, and deployment",
      "Project delivery within 8-12 weeks",
    ],
  },
];

export const ContentWritingPlans = [
  {
    name: "Basic",
    price: "$100",
    features: [
      "500-word blog or article",
      "SEO-optimized content",
      "1 revision",
      "Delivery within 2 days",
      "Topic research included",
      "Grammar and plagiarism check",
    ],
  },
  {
    name: "Standard",
    price: "$250",
    features: [
      "1,000-word blog, article, or web copy",
      "SEO-optimized content",
      "2 revisions",
      "Delivery within 3-5 days",
      "Topic research & keyword optimization",
      "Meta descriptions included",
      "Grammar and plagiarism check",
    ],
  },
  {
    name: "Premium",
    price: "$500",
    features: [
      "2,000-word blog, article, web copy, or eBook",
      "SEO-optimized, high-quality content",
      "Unlimited revisions",
      "Delivery within 5-7 days",
      "In-depth topic research & keyword strategy",
      "Meta descriptions & headers",
      "Content strategy consultation",
      "Grammar, plagiarism, and readability check",
    ],
  },
];



export default WebPlans;
