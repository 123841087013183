import React, { useEffect } from "react";
import "../css/seo.css";
import SeoLottieAnimation from "../components/SeoAnimation";
import Typewriter from "../components/TypeWriter.jsx";
import { useNavigate } from "react-router-dom";
import contentlImage from "../assets/images/SeoImages/media-marketing-internet-digital-global_53876-138500.jpg";
import seoSearchImage from "../assets/images/SeoImages/seo-search-engine-optimization-modish-ecommerce-online-retail-business_31965-45082.jpeg";
import seoExpertImage from "../assets/images/SeoImages/seo-expert-implementing-schema-markup-enhance-search-engine-results_1314467-47979.jpeg";
import seoImage from "../assets/images/SeoImages/seo-search-engine-optimization-modish-ecommerce-online-retail-business_31965-75056.jpeg";
import seoResearchImage from "../assets/images/SeoImages/research.jpeg";
import seoLinking from "../assets/images/SeoImages/man-is-using-laptop-with-website-web-screen_1282070-12983.jpeg";
import seoTechnicalAudit from "../assets/images/SeoImages/man-concentrates-data-analysis-computer_999616-2868-removebg-preview.png";
import MoreServices from "../components/moreServices/moreServices.jsx";
import ServiceForm from "../components/ServiceForm.jsx";
import ContactForm from "../components/ContactForm.jsx";
import PricingCards from "../components/pricingCards/priceCards.jsx";
import ConsultationBanner from "../components/consultationBanner/consultationBanner.jsx";
import WebAgency from "../components/webAgency/webAgency.jsx";
import SeoCards from "../components/SeoCards.jsx";

const Seo = ({plans}) => {
  const navigate = useNavigate();

  useEffect(() => {
    const elements = document.querySelectorAll(".fade-in-left, .fade-in-right");

    const handleScroll = () => {
      elements.forEach((element) => {
        const rect = element.getBoundingClientRect();
        if (rect.top < window.innerHeight && rect.bottom > 0) {
          element.classList.add("in-view");
        } else {
          element.classList.remove("in-view");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Check on mount

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <ServiceForm
        title={
          "Professional SEO Services in UAE – Drive Results, Boost Rankings"
        }
        description={
          "At Softmark Solutions, we offer tailored SEO services, including on-page, off-page, local, and technical SEO. Achieve top rankings with a trusted SEO company in Dubai!"
        }
        buttonText={"Get In Touch"}
      />

      <div className="seo container">
        <h1 className="seo-h fade-in-left">
          SoftMark Solutions -{" "}
          <Typewriter strings={["How Our SEO Experts Can Help?"]} />
        </h1>

        <div className="horizontal-fram-seo fade-in-left">
          <div style={{ width: "100%" }}>
            <p className="seo-p">
              When it comes to SEO, two things can make a mark difference:
              Experience and Knowledge. At Softmark Solutions, with our decades
              of experience, technical knowledge, and hard work, we aim to
              deliver 100% proven results. Our SEO experts are highly skilled,
              creative, and passionate about helping businesses like YOURs to
              succeed. From consultancy to content marketing, we’re providing a
              range of SEO services that are specifically designed to fit your
              business needs.
            </p>
          </div>
          <div className="seo-animation">
            <SeoLottieAnimation />
          </div>
        </div>
        <button
          className="contact-btn"
          style={{ marginBottom: "20px", padding: "20px" }}
          onClick={() => navigate("/portfolio-detail/seo/Seo%20Development")}
        >
          Discover Our SEO Portfolio
        </button>

        <div className="card-heading-service">
        <h2 className="heading-underline-service">
        Comprehensive SEO Services to Elevate Your Online Presence
        </h2>
        <div className="container text" style={{ marginTop: "2rem" }}>
          <p>
          From on-page SEO and off-page SEO to technical and local SEO services, our Dubai SEO agency ensures your business ranks higher, drives traffic, and achieves growth.
          </p>
        </div>
      </div>

      <div className="upcards">
        <SeoCards />
      </div>

        {/* <button className="button-primary" onClick={() => navigate('/contactUs')}>Request a Proposal Today!</button> */}

        {/* <h1 className="seo-subheader fade-in-right">Our SEO Services</h1> */}

        <div className="horizontal-fram-seo fade-in-left" style={{paddingTop:'100px'}}>
          <div className="seo-animation">
            <img
              src={seoSearchImage}
              alt="SEO Consultancy"
              className="seo-consultancy-image"
            />
          </div>
          <div style={{ width: "100%" }}>
            <h1 className="seo-subheader">SEO Consultancy</h1>
            <p className="seo-p">
              With our SEO consultancy services, we’ll guide you through the
              complexities of SEO. If you’re unsure of where to start, there is
              no need to worry about it because we’ll assess your current SEO
              strategy and pinpoint areas for improvement. Our consultants will
              closely work with you to make sure that your SEO strategies are
              completely aligned with your business goals. You’ll receive clear
              and practical advices to boost your website
            </p>
          </div>
        </div>

        <div className="horizontal-fram-seo fade-in-right">
          <div style={{ width: "100%" }}>
            <h1 className="seo-subheader">Technical SEO Audit</h1>
            <p className="seo-p">
              A well-functioning website is essential for SEO success. Our
              technical SEO audit will identify issues that may be holding back
              your site. We analyze everything, such as site speed, mobile
              optimization, and crawlability.
            </p>
            <p className="seo-p">
              After the audit, we’ll provide crawlability and actionable
              recommendations that can help improve your site’s performance. Our
              goal is to make sure that your website is both user-friendly and
              SEO.
            </p>
          </div>
          <div className="seo-animation">
            <img
              src={seoTechnicalAudit}
              alt="Technical SEO Audit"
              className="responsive-image"
            />
          </div>
        </div>

        <div className="horizontal-fram-seo fade-in-left">
          <div className="seo-animation">
            <img
              src={seoImage}
              alt="Keyword & Market Research"
              className="responsive-image"
            />
          </div>
          <div style={{ width: "100%" }}>
            <h1 className="seo-subheader">Keyword & Market Research</h1>
            <p className="seo-p">
              Our keyword and market research services provide in-depth insights
              into what your target audience is looking for. We'll assist you in
              identifying the most relevant keywords for your business and
              target market.
            </p>
            <p className="seo-p">
              This data enables us to create strategies that target the right
              audience and improve your chances of ranking on the first page.
            </p>
          </div>
        </div>

        <div className="horizontal-fram-seo fade-in-right">
          <div style={{ width: "100%" }}>
            <h1 className="seo-subheader">Onsite SEO</h1>
            <p className="seo-p">
              With our onsite SEO services, we’ll make your website as search
              engine-friendly as possible. We’ll optimize your content, meta
              tags, headers, linking, and other key elements. Our experts will
              also improve your site’s structure to make it easier for visitors
              to navigate. After that, we guarantee you’ll see better rankings
              and engagements from visitors.
            </p>
          </div>
          <div className="seo-animation">
            <img
              src={seoResearchImage}
              alt="Onsite SEO"
              className="responsive-image"
            />
          </div>
        </div>

        <div className="horizontal-fram-seo fade-in-left">
          <div className="seo-animation">
            <img
              src={seoLinking}
              alt="Link Building"
              className="custom-image"
            />
          </div>
          <div style={{ width: "100%" }}>
            <h1 className="seo-subheader">Link Building</h1>
            <p className="seo-p">
              Quality-link building is a method that improves a site’s authority
              and credibility. At Softmark Solutions, we focus on building
              high-quality backlinks that can drive more traffic to your site.
              Our approach is ethical and strategic which will make sure that
              your site gains valuable links that contribute to long-term
              success.
            </p>
          </div>
        </div>

        <div className="horizontal-fram-seo fade-in-right">
          <div style={{ width: "100%" }}>
            <h1 className="seo-subheader">Content Marketing</h1>
            <p className="seo-p">
              We all know that content is king when it comes to SEO. Our content
              marketing services will create valuable and engaging articles/blog
              posts and infographics that resonate with your target audience.
              We'll work with you to develop a content strategy that aligns with
              your brand and SEO goals.
            </p>
          </div>
          <div className="seo-animation">
            <img
              src={contentlImage}
              alt="Content Marketing"
              className="responsive-image"
            />
          </div>
        </div>

        <div className="main-container container">
          <h2>Affordable SEO Services Tailored to Your Needs</h2>
          <h5 className="last-paragraph">
            Softmark Solutions offers flexible pricing for Dubai SEO services.
            Whether you need technical SEO, local SEO, or eCommerce solutions,
            we’ve got a plan for you.
          </h5>
        </div>

        <div className="plans-container container">
          <PricingCards pricingPlans={plans} />
        </div>

        <h1>Are you ready to take your SEO to the next level?</h1>
        <p className="seo-p custom-text">
          Softmark Solutions is here to help all businesses, either large or
          small. Our professional and expert team will handle all your SEO
          needs.
        </p>
        <p className="seo-p custom-text">
          You can contact us today or request a proposal that is tailored to
          your specific needs. Let us help you grow your online presence and
          achieve the success you deserve!
        </p>
        <button
          className="button-secondary"
          onClick={() => navigate("/contactUs")}
        >
          Request a Proposal Today!
        </button>

        {/* <PlansSeo plans={SeoPlans} /> */}
      </div>
      <ConsultationBanner
        heading={"Ready to Transform Your Online Visibility?"}
        para={`Partner with Softmark Solutions for top-notch SEO services in Dubai. Let’s boost your traffic and rankings with proven strategies. Contact our SEO company in UAE today!`}
      />
      <WebAgency
        heading={`Lead the Search, Lead the Market with Softmark Solutions`}
        para1={`Lead the Search, Lead the Market" defines our approach to SEO services. At Softmark Solutions, we ensure your business is the first choice for online searchers in Dubai and beyond. With our local SEO services, technical expertise, and proven digital marketing strategies, we position your brand ahead of the competition.`}
        para2={`Our SEO agency in Dubai combines international insights with a focus on local markets, helping businesses excel. Whether it’s eCommerce SEO or off-page SEO services, we prioritize visibility, engagement, and long-term results. Trust Softmark Solutions to turn your online presence into a market leader.`}
      />
      <MoreServices
        heading={"Discover More Digital Solutions with Softmark Solutions"}
        para={`At Softmark Solutions, we offer a full range of digital marketing services alongside SEO services in Dubai. Elevate your brand with tailored strategies today!`}
      />
      <ContactForm
        heading={"Get in Touch with the Best SEO Agency in Dubai"}
        para={`Need expert SEO services in Dubai? Contact Softmark Solutions for affordable and effective solutions. Let’s boost your rankings—message us today!`}
      />
    </>
  );
};

export default Seo;
