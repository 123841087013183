import * as React from "react";
import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardActionArea } from "@mui/material";
// import "../css/Plans.css";
import "../css/planweb.css";
import ModalformBasicWeb from "../components/Modal/web-dev/Modal-Web-Basic";
import ModalformStandardWeb from "../components/Modal/web-dev/Modal-Web-Standard";
import ModalformPremiumWeb from "../components/Modal/web-dev/Modal-Web-Premium";
import WebCards from "../components/Webcards";
import WebLottieAnimation from "../components/WebAnimation";
import Typewriter from "../components/TypeWriter.jsx";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ConsultationBanner from "../components/consultationBanner/consultationBanner.jsx";
import WebAgency from "../components/webAgency/webAgency.jsx";
import MoreServices from "../components/moreServices/moreServices.jsx";
import ServiceForm from "../components/ServiceForm.jsx";
import { Description } from "@mui/icons-material";
import PricingCards from "../components/pricingCards/priceCards.jsx";

import ContactForm from "../components/ContactForm.jsx";

export default function PlansWeb({ plans }) {
  const navigate = useNavigate();

  const [showBasic, setShowBasic] = useState(false);
  const [showStandard, setShowStandard] = useState(false);
  const [showPremium, setShowPremium] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("");

  const handleCloseBasic = () => setShowBasic(false);
  const handleCloseStandard = () => setShowStandard(false);
  const handleClosePremium = () => setShowPremium(false);

  const handleShow = (planName) => {
    setSelectedPlan(planName);
    if (planName === " Basic ") setShowBasic(true);
    if (planName === "Standard") setShowStandard(true);
    if (planName === "Premium") setShowPremium(true);
  };

  return (
    <>
      <ServiceForm
        title={
          "Professional Website Development Services for Modern Businesses"
        }
        description={
          "At Softmark Solutions, our website development services offer tailored solutions to create dynamic, user-friendly websites. From design to performance optimization, we ensure your online presence drives growth and delivers a seamless user experience."
        }
        buttonText={"Get In Touch"}
      />
      <div className="card-heading-service">
        <h2 className="heading-underline-service">
          Explore Our Comprehensive Website Development Services
        </h2>
      </div>
      <div className="container text">
        <p>
          Softmark Solutions provides end-to-end website development services,
          including custom web design, responsive designs, e-commerce solutions,
          content management systems, and reliable maintenance support.
          Transform your online presence with our tailored expertise!
        </p>
      </div>
      <div className="upcards">
        <WebCards />
      </div>

      <div className="main-container container">
        <h2>
          SoftMark Solutions -{" "}
          <Typewriter strings={["Building Your Digital Future"]} />
        </h2>
        <div className="horizontal-fram-web">
          <h5 className="sub-paragraph">
            At SoftMark Solutions, we recognize the critical role a well-crafted
            website plays in your business success. Our expert team specializes
            in creating responsive, user- friendly, and visually stunning
            websites that not only captivate your audience but also drive
            business growth. Whether you need a simple informational site or a
            complex e-commerce platform, we are dedicated to bringing your
            vision to life and ensuring your online presence stands out in a
            crowded digital landscape
          </h5>
          <div className="webanimation">
            {" "}
            <WebLottieAnimation />
          </div>
        </div>
      </div>

      <div className="main-container container">
        {/* <ParticleEffect /> */}
        <h2>Affordable Website Development Services Packages</h2>
        <h5 className="last-paragraph">
          At Softmark Solutions, we offer flexible pricing plans to suit
          businesses of all sizes. Our packages include essential features like
          custom designs, responsive layouts, and advanced functionalities to
          deliver exceptional value. Choose the plan that meets your needs and
          budget!
        </h5>
      </div>

      <div className="plans-container container">
        <PricingCards pricingPlans={plans} />
        {/* {plans.map((plan, index) => (
          <Card
            className={`plan-card plan-card-${index}`}
            key={index}
            style={{
              backgroundColor: "transparent",
            }}>
            <CardActionArea>
              <CardContent>
                <div className="plans-cont">
                  <div className="plan-header">
                    <span className="plan-title">
                      {plan.name}
                    </span>
                    <button
                      className="select-button"
                      onClick={() =>
                        handleShow(plan.name)
                      }
                      aria-label={`Select ${plan.name} plan`}>
                      START YOUR PLAN
                    </button>
                  </div>
                  <div className="plan-features-container">
                    <ul className="plan-features">
                      {plan.features.map(
                        (feature, i) => (
                          <li key={i}>
                            <i className="fa fa-check-circle"></i>{" "}
                            {feature}
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </div>
              </CardContent>
            </CardActionArea>
          </Card>
        ))} */}
        <button
          className="contact-btn"
          onClick={() => navigate("/portfolio-detail/web/Web%20Development")}
        >
          Explore Our Portfolio of Web Applications
        </button>
      </div>

      <ModalformBasicWeb
        isOpened={showBasic}
        heading={selectedPlan + " Plan"}
        handleClose={handleCloseBasic}
      />

      <ModalformStandardWeb
        isOpened={showStandard}
        heading={selectedPlan + " Plan"}
        handleClose={handleCloseStandard}
      />

      <ModalformPremiumWeb
        isOpened={showPremium}
        heading={selectedPlan + " Plan"}
        handleClose={handleClosePremium}
      />

      <ConsultationBanner
        heading={"Take the First Step Toward Your Perfect Website"}
        para={
          "Unlock the potential of your online presence with our expert website development services. Click below to get a free consultation today!"
        }
      />
      <WebAgency
        heading={`Partner with Softmark Solutions – Your Trusted Web Development
            Company`}
        para1={`Creating a successful website involves more than just design; it
            requires expert knowledge in client-side coding, server-side
            programming, and database technology. As a leading web development
            company, Softmark Solutions ensures your website is functional,
            responsive, and optimized for an exceptional user experience.
            Whether you need custom website development services or advanced
            e-commerce solutions, our team is dedicated to delivering websites
            that perform flawlessly.`}
        para2={`An outdated or malfunctioning website can
            harm your brand and drive customers away. With our comprehensive web
            development services, we focus on building reliable, modern websites
            that align with your business goals. From interactive designs to
            robust backend systems, we prioritize quality and long-term
            usability. Choose Softmark Solutions as your trusted web development
            agency and elevate your online presence today!`}
      />
      <MoreServices
        heading={"Explore Our Wide Range of Digital Services"}
        para={`At Softmark Solutions, we go beyond website development services to meet
        all your digital needs. From innovative digital marketing strategies to
        branding solutions, our expert team ensures your business stays ahead in
        the competitive online world. Discover services tailored to drive your
        success!`}
      />
      <ContactForm
        heading={"Get in Touch for Expert Website Development Services"}
        para={`Looking for reliable custom website development services or advanced solutions for your business? Softmark Solutions offers personalized and comprehensive web development services to bring your vision to life. Contact us today for tailored solutions that set your brand apart!`}
      />
    </>
  );
}

PlansWeb.propTypes = {
  plans: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      features: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ).isRequired,
};
