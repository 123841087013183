import React from "react";
import { Box, Grid, Typography, Paper } from "@mui/material";
import Img from "../../assets/images/digitalMarketingTeam.jpg"

const MarketingSection = () => {
  return (
    <Box
      sx={{
        padding: { xs: 2, sm: 4, md: 8 },
        backgroundColor: "#f5f5f5",
        borderRadius: 2,
      }}
    >
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{
              borderRadius: 2,
              overflow: "hidden",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <img
              src={Img}
              alt="Team working together"
              style={{ width: "100%", height: "auto" }}
            />
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h4" component="h3" gutterBottom textAlign="left" color="#4599B4" fontWeight="bold" className="container">
          Results-Driven Digital Marketing Agency in Dubai with Proven Success
          </Typography>
          <Typography variant="body1" paragraph textAlign="left" className="container">
            Looking for a trusted digital marketing company in Dubai to elevate your brand and achieve real growth? Our team at SoftMark Solutions offers a comprehensive range of services, including SEO, web design, PPC, social media marketing, and more. We’re dedicated to delivering results that matter, backed by data, creativity, and experience. With us, you’ll find a strategic partner focused on your unique goals and committed to driving your business forward.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MarketingSection;
