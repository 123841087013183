import React from "react";
import HeroSection from "../components/HeroSection";
import Cards from "../components/Cards";
import OurClients from "../components/Slider";
import ContactForm from "../components/ContactForm";
import ScrollUpButton from "../components/ScrollUpButton";
import WhoWeAre from "../components/whoWeAre/whoWeAre";
import CallToAction from "../components/callToAction/callToActions";
import InfoCards from "../components/infoCards/infoCards";
import ConsultationBanner from "../components/consultationBanner/consultationBanner";
import MarketingSection from "../components/marketingSection/marketingSection";
import ClientsSection from "../components/clients/clients";
import WhatClientSays from "../components/whatClientSays/whatClientSays";
import WorkingProcess from "../components/WorkingProcess";

const Home = () => {
  return (
    <>
      <HeroSection />
      <InfoCards />
      <ConsultationBanner heading={`Your Trusted Digital Marketing Agency for Transformative Results`} para={`Our digital marketing solutions are designed to do more than just generate leads—they turn your brand into a market leader. As a creative digital marketing agency in Dubai, we specialize in crafting strategies that drive growth, boost engagement, and establish lasting customer connections. Partner with us to experience unparalleled success and business growth.`} btnText={`Request a Free Consultation`} />
      {/* <CallToAction /> */}
      <MarketingSection />
      <Cards heading={`Our Digital Marketing Services`} subHeading={`Get On Board With Best Digital Marketing Agency`} para={`Discover tailored digital marketing solutions with a trusted agency in Dubai. From SEO to social media and content marketing, our experts craft strategies designed for your growth. Let’s discuss how we can drive your business forward.`} />
      <OurClients />
      <WorkingProcess/>
      <ConsultationBanner heading={`Let’s Elevate Your Brand with Expert Digital Marketing Solutions`} para={`Ready to partner with a top digital marketing agency in Dubai? Share your vision with us, and our skilled team will design a tailored strategy to bring it to life. Reach out today to schedule a consultation and explore how our innovative solutions can drive your business growth.`} btnText={`GET A QUOTE`} />
      {/* <WhoWeAre /> */}
      <WhatClientSays />
      <ClientsSection />
      {/* <ContactForm /> */}
      <ConsultationBanner heading={`Ready to Grow? Partner with a Digital Marketing Agency That Delivers`} para={`It's time to stop imagining success and start achieving it. Book a consultation with us today. Whether you're a startup or an established business, we’ll boost your ROI with tailored solutions in SEO, web design, content, and more. Let’s make your business shine!`} btnText={`GET A QUOTE`}/>
      <ScrollUpButton />
    </>
  );
};

export default Home;
