import React, { useEffect, Suspense, lazy } from "react";
import "../css/hero.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import GearLottieAnimation from "./Gear-animation";
import ScrollReveal from "scrollreveal";
import MarketLottieAnimation from "./Market-animation";
import { useNavigate } from "react-router-dom";
import DMLottieAnimation from "../components/HeroAnimation";

const LogoParticles = lazy(() => import("./ParticleLogo"));

const HeroSection = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const sr = ScrollReveal({
      origin: "bottom",
      distance: "20px",
      duration: 500,
      delay: 200,
      reset: false,
    });

    sr.reveal(".leftGlobe, .first-line, .second-line", { interval: 200 });
    sr.reveal(".rightGlobe", { interval: 200, origin: "top" });

    return () => sr.destroy();
  }, []);

  return (
    <div className="hero-image ">
      <div className="heroContainer container">
        <div className="hero container">
          <div className="first-line fade-in">
            Leading Digital Marketing{" "}
            <span className="technology">
              <div className="gear">
                <GearLottieAnimation />
              </div>
              <div> Agency</div>
            </span>
          </div>

          <div className="second-line fade-in">
            in the UAE Innovative Growth{" "}
            <span className="marketing">
              <div className="market">
                <MarketLottieAnimation />
              </div>
            </span>
          </div>

          <p style={{ textAlign: "justify", fontSize: "1rem" }}>
            At SoftMark Solutions, we combine the latest in technology with
            strategic marketing to help businesses in the UAE achieve measurable
            growth. Our team of experts specializes in digital solutions
            tailored to your needs, from SEO and social media management to
            data-driven campaigns and analytics. Partner with us to elevate your
            brand, drive engagement, and achieve results that matter.
          </p>

          <div className="hero-btn-div" style={{ marginTop: "1rem" }}>
            <button className="hero-btn" onClick={() => navigate("/contactUs")}>
              GET A QUOTE
            </button>
          </div>
        </div>

        <div className="rightanimation">
          <div style={{width: "100%", height: "100%"}}>
          <Suspense>
            <DMLottieAnimation />
          </Suspense>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
