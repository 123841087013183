import React from 'react'
import '../css/Cards.css'
import Singlecard from './Singlecard'
import SeoAudit from '../assets/icons/seoIcon.png'
import KeywordResearch from '../assets/icons/keyword.png'
import OnPageOptimization from '../assets/icons/speed.png'
import ContentCreation from '../assets/icons/content.png'
import LinkBuilding from '../assets/icons/link.png'
import LocalSEO from '../assets/icons/sign.png'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const SeoCards = ({ heading }) => {
  const SeoData = [
    {
      path: SeoAudit,
      title: 'SEO Audit Services - Optimize Your Website',
      desc: 'Discover opportunities to improve your website’s search engine performance with a comprehensive SEO audit. We identify technical issues, content gaps, and ranking opportunities to boost visibility.'
    },
    {
      path: KeywordResearch,
      title: 'Keyword Research Services - Target the Right Audience',
      desc: 'Find the best keywords to rank for with our data-driven keyword research services. We uncover high-traffic, low-competition keywords to maximize your search engine reach.'
    },
    {
      path: OnPageOptimization,
      title: 'On-Page SEO Services - Improve Your Rankings',
      desc: 'Optimize your website content, meta tags, and structure with our on-page SEO services. We ensure your site is search engine-friendly and ranks higher for targeted keywords.'
    },
    {
      path: ContentCreation,
      title: 'SEO Content Creation - Engage and Rank Higher',
      desc: 'Create high-quality, optimized content that drives traffic and engages your audience. Our SEO content writing services deliver blogs, articles, and web copy that rank and convert.'
    },
    {
      path: LinkBuilding,
      title: 'Link Building Services - Build Authority',
      desc: 'Boost your domain authority with ethical and effective link-building strategies. We acquire high-quality backlinks to enhance your website’s credibility and improve search rankings.'
    },
    {
      path: LocalSEO,
      title: 'Local SEO Services - Dominate Local Searches',
      desc: 'Increase visibility in local search results with our specialized Local SEO services. From Google My Business optimization to local citations, we help you attract nearby customers.'
    }
  ];
  
      
      
      const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      };
    

  return (
    <div>
      <div className='card-heading-service' id='services'>
        <h2 className='heading-underline-service' id='services'>
          {heading}
        </h2>
      </div>
      <div className='cards-container container'>
      <Slider
          {...settings}
          style={{
            width: "100%",
            margin: "0 auto", 
          }}>
        {SeoData.map((item, index) => (
          <Singlecard
            key={index}
            path={item.path}
            title={item.title}
            description={item.desc}
            route={item.route}
          />
        ))}
        </Slider>
      </div>
    </div>
  )
}

export default SeoCards
